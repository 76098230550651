import React, { useEffect } from "react";
import "../styles.scss";

const Team = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="about">
			<div className="cover">
				<div className="team-photo"></div>
				<div className="bar">
					<h2>PicoCoders</h2>
				</div>
			</div>
			<div className="content">
				<p>
					Our mission is to help these businesses succeed by providing
					them with the kinds of expert support they need—from
					branding advice through implementation—to transform their
					projects into successful brands!
				</p>
			</div>
		</div>
	);
};

export default Team;

