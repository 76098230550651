import chImpact01 from "../assets/photos/impact01.jpg";
import chImpact02 from "../assets/photos/impact02.jpg";
import chImpact03 from "../assets/photos/impact03.png";
import chImpact04 from "../assets/photos/impact04.jpg";
import chImpact05 from "../assets/photos/impact05.jpg";
import chImpact06 from "../assets/photos/impact06.jpg";
import chImpact07 from "../assets/photos/impact07.jpg";
import chImpact08 from "../assets/photos/impact08.jpg";

export const impactPratices = [
	{
		id: 0,
		image: chImpact01,
		description: "Opportunity",
		text: "We motivate our employees to pursue their dreams.",
	},
	{
		id: 1,
		image: chImpact02,
		description: "Recyclable cups",
		text: "Recyclable cups that keep your coffee warm.",
	},
	{
		id: 2,
		image: chImpact03,
		description: "Paper bag",
		text: "All our products have paper packaging.",
	},
	{
		id: 3,
		image: chImpact04,
		description: "Inclusion & Diversity",
		text: "An environment where differences are embraced.",
	},
	{
		id: 4,
		image: chImpact05,
		description: "Compost",
		text: "We take care of the soil health with compost.",
	},
	{
		id: 5,
		image: chImpact06,
		description: "Wooden cutlery",
		text: "We only have wooden cutlery. Charming, right?",
	},
	{
		id: 6,
		image: chImpact07,
		description: "Paper straws",
		text: "All our straws are 100% paper. Good for nature.",
	},
	{
		id: 7,
		image: chImpact08,
		description: "Recycling bins",
		text: "Trash bins separated by category.",
	},
];
