export const socialMedia = [
    {
        name: 'Facebook',
        icon: 'fab fa-facebook',
        link: 'https://facebook.com/',
    },
    {
        name: 'Instagram',
        icon: 'fab fa-instagram',
        link: 'https://instagram.com/',
    },
    {
        name: 'Youtube',
        icon: 'fab fa-youtube',
        link: 'https://youtube.com/',
    },
    {
        name: 'Pinterest',
        icon: 'fab fa-pinterest',
        link: 'https://pinterest.com/',
    },
    {
        name: 'Spotify',
        icon: 'fab fa-spotify',
        link: 'https://spotify.com/',
    },
    {
        name: 'Linkedin',
        icon: 'fab fa-linkedin',
        link: 'https://linkedin.com/',
    },
]