import chbanner01 from "../assets/photos/chbanner01.png";
import chbanner02 from "../assets/photos/chbanner02.png";
import chbanner03 from "../assets/photos/chbanner03.png";
import chbannerSquare01 from "../assets/photos/chbanner-square01.jpg";
import chbannerSquare02 from "../assets/photos/chbanner-square02.jpg";
import chbannerSquare03 from "../assets/photos/chbanner-square03.jpg";

export const sliderData = [
	{
		landscape: chbanner01,
		square: chbannerSquare01,
	},
	{
		landscape: chbanner02,
		square: chbannerSquare02,
	},
	{
		landscape: chbanner03,
		square: chbannerSquare03,
	},
];
